import {
  IServerSideGetRowsRequest,
  LoadSuccessParams,
} from '@ag-grid-community/core';
import { createAction, props } from '@ngrx/store';
import { PublishProduct } from '@prisma-client/rating';
import { AddPayeeDialogResult } from '@roadrunner/rating-utility/ui-add-payee';
import { AddProductDialogResult } from '@roadrunner/rating-utility/ui-add-product';
import { IGetDealerCostRoundingBucketOptionsResponse } from '../../apollo/queries/get-dealer-cost-rounding-bucket-options/get-dealer-cost-rounding-bucket-options.interface';
import { DealerRoundingResponse } from '../../apollo/queries/get-dealer-cost-rounding/get-dealer-cost-rounding.interface';
import { NonSellableCombinationsViewModel } from '../../apollo/queries/get-non-sellable-combinations/get-non-sellable-combinations.interface';
import { GetParametersResponse } from '../../apollo/queries/get-parameters/get-parameters.interface';
import { GetProductParameterKeysResponse } from '../../apollo/queries/get-product-parameter-keys-by-product-id/get-product-parameter-keys-by-product-id.interface';
import {
  IABucket,
  IGetOffsetBucketListByProductResponse,
} from '../../apollo/queries/products/bucket-list-by-product/bucket-list-by-product.interface';
import { ProductMsrpOperation } from '../../models/view-models/products/msrp-operations.view-model';
import { NonSellableCombination } from '../../models/view-models/products/non-sellable-combinations.view-model';
import { Payee } from '../../models/view-models/products/payee.interface';
import { IAProductType } from '../product/product.actions';

// PAGE 1
export const getProductTypeList = createAction(
  '[Rates Page] Get Product type list By Program'
);
export const getProductTypeListSuccess = createAction(
  '[Rates Page] Get Product type list By Program Success',
  props<{ productTypes: IAProductType[] }>()
);
export const getProductTypeListFailure = createAction(
  '[Rates Page] Get Product type list By Program Failure',
  props<{ error: unknown }>()
);

// PAGE 2
export const saveDealerCostRounding = createAction(
  '[Rates Page] Save Dealer Cost Rounding',
  props<{
    isEnabled: boolean;
    roundingValue: number;
    roundingType: string;
    offsetBucketId: number;
  }>()
);
export const saveDealerCostRoundingSuccess = createAction(
  '[Rates Page] Save Dealer Cost Rounding Success',
  props<{
    oldDealerCostRounding: DealerRoundingResponse | null;
    newDealerCostRounding: DealerRoundingResponse | null;
    parameterKeys: GetProductParameterKeysResponse;
  }>()
);
export const saveDealerCostRoundingFailure = createAction(
  '[Rates Page] Save Dealer Cost Rounding Failure',
  props<{ error: unknown }>()
);

export const ratesPublishProduct = createAction('[Rates Page] Publish Product');
export const ratesPublishProductSuccess = createAction(
  '[Publish API] Publish Product Success',
  props<{ publishProduct: PublishProduct }>()
);
export const ratesPublishProductCancelled = createAction(
  '[Publish API] Publish Product Cancelled'
);
export const ratesPublishProductFailure = createAction(
  '[Publish API] Publish Product Failure',
  props<{ error: unknown }>()
);

export const addBucketClicked = createAction('[Bucket List] Add Clicked');
export const createBucket = createAction(
  '[Bucket List Page] Create Bucket',
  props<{
    bucketName: string;
    payeeId: number;
    cmsBucketNumber: number | null;
    reserves: boolean;
  }>()
);
export const createBucketSuccess = createAction(
  '[Bucket List Page] Create Bucket Success',
  props<{ bucket: IABucket }>()
);
export const createBucketFailure = createAction(
  '[Bucket List Page] Create Bucket Failure',
  props<{ error: unknown }>()
);

export const loadProductDetail = createAction(
  '[Rates] Load Product Detail',
  props<{ productId: number }>()
);

export const loadProductDetailSuccess = createAction(
  '[Rates API] Load Product Detail Success',
  props<{
    programId: number;
    buckets: IGetOffsetBucketListByProductResponse;
    dealerCostRounding: DealerRoundingResponse;
    dcrBucketOptions: IGetDealerCostRoundingBucketOptionsResponse;
    nonSellableCombinations: NonSellableCombinationsViewModel;
    parameterKeys: GetProductParameterKeysResponse;
    parameters: GetParametersResponse;
    payees: Payee[];
  }>()
);

export const msrpOperationChanged = createAction(
  '[MSRP Component] Operation Changed',
  props<{
    productMsrpParameterKeyCombinationId: number;
    parameterKeyIds: number[];
    operation: ProductMsrpOperation;
    oldOperand: number | null;
  }>()
);
export const msrpOperationDeleted = createAction(
  '[MSRP Component] Operation Deleted',
  props<{
    productMsrpParameterKeyCombinationId: number;
    parameterKeyIds: number[];
    operator: string;
    oldOperand: number | null;
  }>()
);
export const msrpOperationSaveSuccess = createAction(
  '[API] MSRP Operation Save Success',
  props<{
    parameterKeys: string[];
    operator: string;
    oldOperand: number | null;
    newOperand: number | null;
  }>()
);
export const msrpOperationSaveFailure = createAction(
  '[API] MSRP Operation Save Failure',
  props<{ error: unknown }>()
);
export const msrpParameterAdded = createAction(
  '[MSRP Component] Parameter Added',
  props<{ parameterId: number }>()
);
export const msrpParameterAddSuccess = createAction(
  '[API] Add MSRP Parameter Success',
  props<{ parameterId: number; parameterName: string }>()
);
export const msrpParameterAddFailure = createAction(
  '[API] Add MSRP Parameter Failure',
  props<{ error: unknown }>()
);
export const msrpParameterRemoved = createAction(
  '[MSRP Component] Parameter Removed',
  props<{ parameterId: number }>()
);
export const msrpParameterDeleteSuccess = createAction(
  '[API] Delete MSRP Parameter Success',
  props<{ parameterId: number; parameterName: string }>()
);
export const msrpParameterDeleteFailure = createAction(
  '[API] Delete MSRP Parameter Failure',
  props<{ error: unknown }>()
);

export const saveNonSellableCombinations = createAction(
  '[Non Sellable Options Component] Save Non-Sellable Combinations',
  props<{ nonSellableCombinations: NonSellableCombination[] }>()
);
export const saveNonSellableCombinationsSuccess = createAction(
  '[Non Sellable Options Component] Save Non-Sellable Combinations Success',
  props<{
    newNonSellableCombinations: NonSellableCombinationsViewModel;
    oldNonSellableCombinations: NonSellableCombinationsViewModel;
  }>()
);
export const saveNonSellableCombinationsFailure = createAction(
  '[Non Sellable Options Component] Save Non-Sellable Combinations Failure',
  props<{ error: unknown }>()
);

export const msrpOperationsPaged = createAction(
  '[MSRP Component] Paged',
  props<IServerSideGetRowsRequest>()
);
export const msrpOperationsPageLoadSuccess = createAction(
  '[API] MSRP Operations Page Load Success',
  props<LoadSuccessParams>()
);
export const msrpOperationsPageLoadFailure = createAction(
  '[API] MSRP Operations Page Load Failure',
  props<{ error: unknown }>()
);

export const reviewPaged = createAction(
  '[Product Review Component] Paged',
  props<IServerSideGetRowsRequest>()
);
export const reviewPageLoadSuccess = createAction(
  '[API] Product Review Page Load Success',
  props<LoadSuccessParams>()
);
export const reviewPageLoadFailure = createAction(
  '[API] Product Review Page Load Failure',
  props<{ error: unknown }>()
);

export const reviewExportClicked = createAction(
  '[Product Review Component] Export Clicked',
  props<{
    filterModel: { [key: string]: { values: string[] } };
  }>()
);
export const exportProductReviewSuccess = createAction(
  '[API] Product Review Export Success',
  props<{ fileUrl: string }>()
);
export const exportProductReviewFailure = createAction(
  '[API] Product Review Export Failure',
  props<{ error: unknown }>()
);

export const addPayeeClicked = createAction(
  '[Add Bucket Component] Add Payee Clicked'
);
export const addPayee = createAction(
  '[Add Payee Component] Submit',
  props<{ payee: AddPayeeDialogResult }>()
);
export const addPayeeSuccess = createAction(
  '[API] Add Payee Success',
  props<{ payee: Payee }>()
);
export const addPayeeFailure = createAction(
  '[API] Add Payee Failure',
  props<{ error: unknown }>()
);
export const addPayeeCancelled = createAction(
  '[Add Payee] Add Payee Cancelled'
);
export const addProductClicked = createAction(
  '[Rates/Product Search] Add Clicked'
);
export const addProduct = createAction(
  '[Rates/Add Product] Submit',
  props<{ product: AddProductDialogResult }>()
);
export const addProductSuccess = createAction(
  '[Rates/API] Add Product Success',
  props<{ id: number; productTypeId: number; code: string; name: string }>()
);
export const addProductFailure = createAction(
  '[Rates/API] Add Product Failure',
  props<{ error: unknown }>()
);
