import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { trpcClient } from '@roadrunner/shared/util-trpc';
import { BaseLogMessage } from '../models/base-log-message.interface';

@Injectable({
  providedIn: 'root',
})
export class LoggingDataService {
  constructor() {}

  logMessages<T extends BaseLogMessage>(logs: any): Observable<unknown> {
    return from(trpcClient.saveLogMessages.saveLogMessages.mutate(logs))
    
  }
}
