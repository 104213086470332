import { Observable, from } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { UpdateProductParameterKeyCombinationsResponse } from './update-product-parameter-key-combinations.interface';
import { trpcClient } from '@roadrunner/shared/util-trpc';

export class UpdateProductParameterKeyCombinationsData {
  static updateProductParameterKeyCombinations(
    apollo: Apollo,
    productId: number,
    newParameterKeyIds: number[],
    parameterKeyIdsToDelete: number[],
    parameterCountChanged: boolean
  ): Observable<UpdateProductParameterKeyCombinationsResponse> {
    const resetCombinations = parameterCountChanged;
    const createCombinations =!parameterCountChanged && newParameterKeyIds.length > 0;
    const deleteCombinations =!parameterCountChanged && parameterKeyIdsToDelete.length > 0;

    return from(
      trpcClient.productParameter.updateProductParameterKeyCombinations.mutate({
        productId: productId,
        createCombinations: createCombinations,
        deleteCombinations: deleteCombinations,
        resetCombinations: resetCombinations,
        newParameterKeyIds: `{${newParameterKeyIds.join(',')}}`,
        parameterKeyIdsToDelete: `{${parameterKeyIdsToDelete.join(',')}}`,
      })
    ).pipe(map((res) => res!));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  }
}
