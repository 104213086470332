import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { from,catchError, EMPTY, exhaustMap, map, of } from 'rxjs';
import { trpcClient } from '@roadrunner/shared/util-trpc';
import * as ProgramActions from './program.actions';
import { selectProgramsLoaded } from './program.selectors';

@Injectable()
export class ProgramEffects {
  load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgramActions.programsGuardActivated),
      concatLatestFrom(() => this.store.pipe(select(selectProgramsLoaded))),
      exhaustMap(([_, programsLoaded]) => {
        if (programsLoaded) {
          return EMPTY;
        }
        return from(trpcClient.program.getAll.query()).pipe(
          map((response) => {
            return ProgramActions.loadProgramsSuccess({
              program: response,
            });
          })
        );
      }),
      catchError((error) => {
        return of(ProgramActions.loadProgramsFailure({ error }));
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store
  ) {}
}
