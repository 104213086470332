import { RatingUtilityEnvironment } from './environment.interface';

export const environment: RatingUtilityEnvironment = {
  production: true,
  requireAuth: true,
  azureClientId: '4e128517-a7d0-4313-b718-29ff2c65bf5a',
  azureTenantId: 'b45b2e14-b22d-4196-b48c-b1b5506c874a',
  enableNgRxMiddleware: true,
  enableReduxDevTools: true,
  rateLookupUrl: 'https://roadrunner-rate-lookup-dev.herokuapp.com/',
  logRocketAppId: 'npobc9/roadrunner-ui',
  enableLicensing: true,
  enableFileImportExportMenuItem: true,
  enableFileImportTab: true,
};
