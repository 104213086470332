import { Observable, from } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { UpdateProductMsrpParameterKeyCombinationsResponse } from './update-product-msrp-parameter-key-combinations.interface';
import { trpcClient } from '@roadrunner/shared/util-trpc';

export class UpdateProductMsrpParameterKeyCombinationsData {
  static updateProductMsrpParameterKeyCombinations(
    apollo: Apollo,
    productId: number,
    newParameterKeyIds: number[],
    parameterKeyIdsToDelete: number[],
    parameterCountChanged: boolean
  ): Observable<UpdateProductMsrpParameterKeyCombinationsResponse> {
    const resetCombinations = parameterCountChanged;
    const createCombinations =
      !parameterCountChanged && newParameterKeyIds.length > 0;
    const deleteCombinations =
      !parameterCountChanged && parameterKeyIdsToDelete.length > 0;

    return from(
      trpcClient.productParameter.updateProductMsrpParameterKeyCombinations.mutate(
        {
          productId: productId,
          createCombinations: createCombinations,
          deleteCombinations: deleteCombinations,
          resetCombinations: resetCombinations,
          newParameterKeyIds: `{${newParameterKeyIds.join(',')}}`,
          parameterKeyIdsToDelete: `{${parameterKeyIdsToDelete.join(',')}}`,
        }
      )
    ).pipe(map((res) => res!));
  }
}
