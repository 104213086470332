import { trpcClient } from '@roadrunner/shared/util-trpc';
import { Apollo } from 'apollo-angular';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductMsrpOperation } from '../../../models/view-models/products/msrp-operations.view-model';

export class SaveMsrpOperationData {
  static saveMsrpOperation(
    apollo: Apollo,
    productMsrpParameterKeyCombinationId: number,
    operation: ProductMsrpOperation
  ) {
    return from(
      trpcClient.productMsrp.saveProductMsrpOperation.mutate({
        combinationId: productMsrpParameterKeyCombinationId,
        operand: operation.operand,
        operator: operation.operator,
      })
    ).pipe(map((res) => res));;
  }

  static deleteMsrpOperation(
    apollo: Apollo,
    productMsrpParameterKeyCombinationId: number,
    operator: string
  ) {
      return from(
        trpcClient.productMsrp.deleteProductMsrpOperation.mutate({
          combinationId: productMsrpParameterKeyCombinationId,
          operator:operator,
        })
      ).pipe(map((res) => res));
  }
}
