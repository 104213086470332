import { Injectable } from '@angular/core';
import { trpcClient } from '@roadrunner/shared/util-trpc';
import { from, map, Observable } from 'rxjs';
import {
  AddProgramVariables,
  InsertOneProgramResponse,
} from './state/program.models';

export interface IUpdateProgram {
  id: number;
  cms: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  constructor() {}

  add(program: AddProgramVariables): Observable<InsertOneProgramResponse> {
    return from(
      trpcClient.program.addProgram.mutate({
        agentCode: program.agentCode,
        name: program.name,
        cms: program.cms,
      })
    ).pipe(
      map((response) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return response.insert_program_one;
      })
    );
  }

  update(program: IUpdateProgram) {
    return from(
      trpcClient.program.updateProgram.mutate({
        id: program.id,
        cms: program.cms,
      })
    ).pipe(map((response) => response.update_program_by_pk));
  }
}
