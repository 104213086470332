import { ColDef } from '@ag-grid-community/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LogInfo, ProductLogMessage } from '@roadrunner/shared/client-logging';
import { UserSelectors } from '@roadrunner/shared/data-access-user';
import { numericValueFormatter } from '@roadrunner/shared/ui-grid';
import { calculateTotalWithRounding } from '@roadrunner/util-math';
import { IABucket } from '../../apollo/queries/products/bucket-list-by-product/bucket-list-by-product.interface';
import { ProductRate } from '../../apollo/queries/products/get-product-rates/get-product-rates.interface';
import { IBucketVM } from '../../models/view-models/buckets/bucket.view-model';
import { IDealerCostRoundingVM } from '../../models/view-models/products/deal-cost-rounding.view-models';
import { IMsrpParametersVM } from '../../models/view-models/products/msrp-parameters.view-model';
import { NonSellableCombination } from '../../models/view-models/products/non-sellable-combinations.view-model';
import { IProductParameterVM } from '../../models/view-models/products/product-options.view-model';
import { ProductTypeListItem } from '../../models/view-models/products/product-type.view-model';
import { SelectedProduct } from '../../models/view-models/products/product.view-model';
import { getMsrpOperationColumnDefinitions } from '../../pages/rates/product/steps/product-msrp/msrp-operation-columns';
import { IAProductType } from '../product/product.actions';
import { selectRouteParam } from '../router-param/router-param.selectors';
import { selectChosenProgram } from '../user/user.selectors';
import { parameterKeyAdapter, rateFeatureKey, State } from './rate.reducers';

const selectRateState = createFeatureSelector<State>(rateFeatureKey);

export const selectStateNonSellableCombinationsResponse = createSelector(
  selectRateState,
  (state: State) => state.nonSellableCombinations
);

const selectStateNonSellableCombinations = createSelector(
  selectStateNonSellableCombinationsResponse,
  (nonSellableCombinations) =>
    nonSellableCombinations?.non_sellable_combinations ?? []
);

const selectProductParameterKeys = createSelector(
  selectRateState,
  (state: State) => state.productParameterKeys
);

const {
  selectEntities: selectProductParameterKeyEntities,
  selectAll: selectAllProductParameterKeys,
} = parameterKeyAdapter.getSelectors(selectProductParameterKeys);

export const selectParameterIdsByKeyId = createSelector(
  selectAllProductParameterKeys,
  (parameterKeys) => {
    const map = new Map<number, number>();
    for (const pk of parameterKeys) {
      map.set(pk.id, pk.parameter_id);
    }
    return map;
  }
);

const selectRawProductTypeList = createSelector(
  selectRateState,
  (state) => state.productTypeList ?? []
);
export const selectAllProductTypes = createSelector(
  selectRawProductTypeList,
  (productTypes: IAProductType[]) => {
    return productTypes.map((productType): ProductTypeListItem => {
      return {
        id: productType.id,
        name: productType.type,
        description: productType.description,
        products: productType.products,
      };
    });
  }
);
export const selectProductTypeList = createSelector(
  selectAllProductTypes,
  (productTypes) => {
    return productTypes.filter(
      (productType) => productType.products.length > 0
    );
  }
);

export const selectProductIsLoading = createSelector(
  selectRateState,
  (state) => state.isLoadingProductDetail
);

export const selectProductIdParam = createSelector(
  selectRouteParam('productId'),
  (productIdParam) => {
    const productId = +(productIdParam as string);
    if (isNaN(productId)) {
      return null;
    }
    return productId;
  }
);

export const selectChosenProduct = createSelector(
  selectProductIdParam,
  selectProductTypeList,
  (productId, productTypes): SelectedProduct | null => {
    if (!productId || !productTypes || productTypes.length === 0) {
      return null;
    }
    for (const pt of productTypes) {
      for (const p of pt.products) {
        if (p.id === productId) {
          return {
            id: p.id,
            code: p.code,
            name: p.name,
            productTypeId: pt.id,
          };
        }
      }
    }
    return null;
  }
);

export const selectChosenProductType = createSelector(
  selectChosenProduct,
  selectProductTypeList,
  (product, productTypes) => {
    if (!product || !productTypes || productTypes.length === 0) {
      return null;
    }
    return productTypes.find((pt) => pt.id === product.productTypeId) ?? null;
  }
);

export const selectProductParameters = createSelector(
  selectRateState,
  (state) => state.productParameters
);

export const selectNonSellableCombinations = createSelector(
  selectStateNonSellableCombinations,
  (combinations) =>
    combinations.map((combo): NonSellableCombination => {
      return {
        id: combo.id,
        parameterKeyIds: combo.non_sellable_combination_options.map(
          (option) => option.parameter_key_id
        ),
      };
    })
);

export const selectIsPublishingProductRates = createSelector(
  selectRateState,
  (state: State) => state.isPublishingProductRates
);
export const selectIsSavingNonSellableCombinations = createSelector(
  selectRateState,
  (state: State) => state.isSavingNonSellableCombinations
);

export const selectIsSavingDealerCostRounding = createSelector(
  selectRateState,
  (state: State) => state.isSavingDealerCostRounding
);

export const selectChosenProductSetupLink = createSelector(
  selectChosenProduct,
  (product): (string | number)[] | null => {
    if (!product) {
      return null;
    }
    return ['/rating', 'products', product.id];
  }
);

export const selectPayees = createSelector(
  selectRateState,
  (state) => state.payees
);

export const selectExistingPayeeCodes = createSelector(selectPayees, (payees) =>
  payees.map((p) => p.code)
);

export const selectExistingPayeeNames = createSelector(selectPayees, (payees) =>
  payees.map((p) => p.company)
);

export const selectIsAddingBucket = createSelector(
  selectRateState,
  (state) => state.isAddingBucket
);

const selectBuckets = createSelector(
  selectRateState,
  (state) => state.bucketList
);

export const selectBucketList = createSelector(
  selectBuckets,
  selectProductIdParam,
  (buckets, productId) => {
    return buckets
      .map((bucket: IABucket): IBucketVM => {
        return {
          id: bucket.id,
          name: bucket.name,
          payee: {
            id: bucket.payee.id,
            company: bucket.payee.company,
            code: bucket.payee.code,
          },
          has_saved_rates: bucket.has_saved_rates,
          bucket_rates: [],
          // TODO: remove !s
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          product: null!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          productId: productId!,
          sortOrder: bucket.sort_order,
        };
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
  }
);

export const selectBucketNames = createSelector(selectBucketList, (buckets) =>
  buckets.map((b) => b.name)
);

export const selectNextBucketSortOrder = createSelector(
  selectBucketList,
  (buckets) => {
    if (buckets.length === 0) {
      return 0;
    }
    const max = buckets.reduce(
      (max, b) => (b.sortOrder > max ? b.sortOrder : max),
      0
    );
    return max + 1;
  }
);

export const selectDealerCostRoundingResponse = createSelector(
  selectRateState,
  (state) => state.dealerCostRounding
);

export const selectRoundingId = createSelector(
  selectRateState,
  (state: State) => state?.dealerCostRounding?.id ?? undefined
);

export const selectDealerCostRounding = createSelector(
  selectRateState,
  (state: State): IDealerCostRoundingVM | null => {
    return state?.dealerCostRounding
      ? {
          offsetBucket: {
            id: state.dealerCostRounding.offset_bucket.id,
            name: state.dealerCostRounding.offset_bucket.name,
            has_saved_rates: true,
            // TODO: remove !s
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            payee: null!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            bucket_rates: null!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            product: null!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            productId: null!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sortOrder: null!,
          },
          roundTo: state.dealerCostRounding.rounding_value,
          roundingType: state.dealerCostRounding.rounding_type,
        }
      : null;
  }
);

export const selectDealerCostOffsetBucketList = createSelector(
  selectRateState,
  (state: State) => {
    return state?.dealerCostOffsetBucketOptions?.product_by_pk?.buckets
      ? state.dealerCostOffsetBucketOptions.product_by_pk.buckets.map(
          (b) =>
            ({
              id: b.id,
              name: b.name,
            } as IBucketVM)
        )
      : [];
  }
);

export const selectIsSavingMsrp = createSelector(
  selectRateState,
  (state) => state.isSavingMsrpParameters
);

export const selectMsrpParameterIds = createSelector(
  selectRateState,
  (state) => state.msrpParameterIds
);

export const selectSavedMsrpParameterIds = createSelector(
  selectRateState,
  (state) => state.savedMsrpParameterIds
);

export const selectMsrpParameters = createSelector(
  selectMsrpParameterIds,
  selectProductParameters,
  (msrpParameterIds, parameters) => {
    return getMsrpParametersVM(msrpParameterIds, parameters);
  }
);

export const selectSavedMsrpParameters = createSelector(
  selectSavedMsrpParameterIds,
  selectProductParameters,
  (msrpParameterIds, parameters) => {
    return getMsrpParametersVM(msrpParameterIds, parameters);
  }
);

function getMsrpParametersVM(
  msrpParameterIds: number[],
  parameters: IProductParameterVM[] | null
): IMsrpParametersVM {
  const msrpParams: IMsrpParametersVM = { unusedParams: [], usedParams: [] };
  if (!msrpParameterIds || !parameters) {
    return msrpParams;
  }
  return parameters.reduce((agg: IMsrpParametersVM, param) => {
    if (msrpParameterIds.includes(param.parameterId)) {
      agg.usedParams.push(param);
    } else {
      agg.unusedParams.push(param);
    }
    return agg;
  }, msrpParams);
}

export const selectIsExportingProductReview = createSelector(
  selectRateState,
  (state) => state.isExportingProductReview
);

export const selectProductLogInfo = createSelector(
  selectChosenProduct,
  selectChosenProgram,
  UserSelectors.selectUserName,
  UserSelectors.selectUserEmail,
  (product, program, userName, userEmail): LogInfo<ProductLogMessage> => {
    return {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      product_id: product!.id,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      program_id: program!.id,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      program: program!.name,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      product: product!.name,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      user_name: userName!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      user_email: userEmail!,
    };
  }
);

export const selectGetDealerCost = createSelector(
  selectDealerCostRounding,
  (dealerCostRounding) => {
    return dealerCostRounding
      ? (data: ProductRate) => {
          return calculateTotalWithRounding(
            data.dealerCost,
            dealerCostRounding.roundingType,
            dealerCostRounding.roundTo
          );
        }
      : (data: ProductRate) => data.dealerCost;
  }
);

export const selectGetBucketTotal = createSelector(
  selectDealerCostRounding,
  (dealerCostRounding) => {
    return (bucketId: number) => {
      return dealerCostRounding &&
        dealerCostRounding.offsetBucket.id === bucketId
        ? (data: ProductRate) => {
            const roundedDealerCost = calculateTotalWithRounding(
              data.dealerCost,
              dealerCostRounding.roundingType,
              dealerCostRounding.roundTo
            );

            return (
              (data.bucketRateTotals.find((brt) => brt.bucketId === bucketId)
                ?.total ?? 0) +
              roundedDealerCost -
              data.dealerCost
            );
          }
        : (data: ProductRate) => {
            return data.bucketRateTotals.find(
              (brt) => brt.bucketId === bucketId
            )?.total;
          };
    };
  }
);

export const selectReviewColumns = createSelector(
  selectGetDealerCost,
  selectGetBucketTotal,
  selectBucketList,
  selectProductParameters,
  selectDealerCostRounding,
  (
    getDealerCost,
    getBucketTotal,
    buckets,
    productParameters,
    dealerCostRounding
  ): ColDef[] => {
    if (buckets.length === 0 || !productParameters?.length) {
      return [];
    }

    return [
      //  Parameters
      ...productParameters.map((parameter, i): ColDef => {
        const parameterKeys = new Map<number, string>(
          parameter.keys.map((key) => [key.parameterKeyId, key.parameterKey])
        );

        return {
          headerName: parameter.parameterName,
          filter: 'agSetColumnFilter',
          field: parameter.parameterId.toString(),
          filterParams: {
            values: parameter.keys.map((key) => key.parameterKey),
          },
          valueGetter: (p) => {
            const returnValue = parameterKeys.get(
              (p.data as ProductRate).parameterKeyIds[i]
            );

            return returnValue;
          },
        };
      }),
      //  Buckets
      ...buckets
        .filter((b) => b.has_saved_rates)
        .map((bucket, _i): ColDef => {
          return {
            field: `bucket-${bucket.id}`,
            headerName: `${bucket.name} - ${bucket.payee.code}`,
            valueGetter: (p) => getBucketTotal(bucket.id)(p.data),
            valueFormatter: numericValueFormatter(),
          };
        }),
      //  Dealer Cost
      {
        headerName: 'Dealer Cost',
        cellStyle: { 'font-weight': 'bold' },
        valueGetter: (p) => getDealerCost(p.data),
        valueFormatter: numericValueFormatter(),
      },
      //  MSRP
      ...getMsrpOperationColumnDefinitions(dealerCostRounding),
    ];
  }
);

export const selectProductCodes = createSelector(
  selectProductTypeList,
  (productTypes) => {
    return productTypes.reduce((productCodes: string[], productType) => {
      return productCodes.concat(productType.products.map((p) => p.code));
    }, []);
  }
);

export class RateTabNames {
  static DealerCostRounding = 'dealer-cost-rounding';
  static NonSellableOptions = 'non-sellable-options';
  static RateSlices = 'rate-slices';
  static Surcharges = 'surcharges';
  static Msrp = 'msrp';
  static DealerCostReview = 'dealer-cost-review';
}

export const orderedRateTabNames = [
  RateTabNames.DealerCostRounding,
  RateTabNames.NonSellableOptions,
  RateTabNames.RateSlices,
  RateTabNames.Surcharges,
  RateTabNames.Msrp,
  RateTabNames.DealerCostReview,
];
