import { createReducer, on } from '@ngrx/store';
import { addProgramSuccess } from '@roadrunner/rating-utility/data-access-program';
import { IBucket } from '../../models/program.interface';
import { ICoverageVM } from '../../models/view-models/products/coverage.view-model';
import { IProductParameterKeyVM } from '../../models/view-models/products/product-options.view-model';
import { DRYUtility } from '../../shared/utility/dry.utility';
import { chooseProgram } from '../user/user.actions';
import { ProductReducersMapping } from './product-reducers.mapping';
import { ProductSettings } from './product-settings.interface';
import * as ProductActions from './product.actions';
import { IAProductType, ParameterFieldsFragment, ProductFieldsFragment, ParameterSubtypeFieldsFragment } from './product.actions';

export const productFeatureKey = 'productState';

export interface State {
  product: ProductFieldsFragment | null;
  parameters: ParameterFieldsFragment[];
  savedProductSettings: ProductSettings | null;
  productTypeList: IAProductType[] | null;
  isSavingProductSettings: boolean;
  isSavingProductParameters: boolean;
  isSavingCoverages: boolean;

  previewBuckets: IBucket[] | null;
  showPreviewProductRatesModal: boolean;
  showCreateBucketModal: boolean;
  deletedNonCoverageParameterKeys: IProductParameterKeyVM[];
  deletedCoverageParameterKeys: ICoverageVM[];
}

export const initialState: State = {
  product: null,
  parameters: [],
  savedProductSettings: null,
  productTypeList: null,
  isSavingProductSettings: false,
  isSavingProductParameters: false,
  isSavingCoverages: false,

  previewBuckets: null,
  showPreviewProductRatesModal: false,
  showCreateBucketModal: false,
  deletedNonCoverageParameterKeys: [],
  deletedCoverageParameterKeys: [],
};



export const productReducer = createReducer(
  initialState,
  on(ProductActions.getProduct, (state, _action): State => {
    return {
      ...state,
      product: null,
      deletedNonCoverageParameterKeys: [],
      deletedCoverageParameterKeys: [],
    };
  }),
  on(ProductActions.getProductSuccess, (state, action): State => {
    return {
      ...state,
      product: action.productRes,
      parameters: action.parameters,
      savedProductSettings: {
        name: action.productRes.name,
        description: action.productRes.description,
        code: action.productRes.code,
        riskType: action.productRes.risk_type!,
      },
    };
  }),
  on(
    ProductActions.getProductTypeList,
    chooseProgram,
    addProgramSuccess,
    (state): State => {
      return {
        ...state,
        productTypeList: [],
      };
    }
  ),
  on(ProductActions.getProductTypeListSuccess, (state, action): State => {
    return { ...state, productTypeList: action.productTypes };
  }),
  on(ProductActions.saveProductSettings, (state): State => {
    return { ...state, isSavingProductSettings: true };
  }),
  on(ProductActions.saveProductSettingsSuccess, (state, action): State => {
    if (!state.product) {
      return state;
    }

    const product = DRYUtility.deepCopy(state.product);
    const res = action.settingsResponse.insert_product_one;

    let productTypes = state.productTypeList;
    if (product.product_type_id !== res.productTypeId) {
      productTypes = productTypes?.slice() ?? [];
      // If a product has been moved from one product type to another, we need to update the productTypes state
      // to reflect that so that things like breadcrumbs & selectors will show products in the right place.
      const previousProductTypeIndex = productTypes.findIndex((pt) =>
        pt.products.some((p) => p.id === res.id)
      );
      if (previousProductTypeIndex >= 0) {
        const previousProductType = productTypes[previousProductTypeIndex];
        productTypes[previousProductTypeIndex] = {
          ...previousProductType,
          products: previousProductType.products.filter((p) => p.id !== res.id),
        };
      }

      const newProductTypeIndex = productTypes.findIndex(
        (pt) => pt.id === res.productTypeId
      );
      if (newProductTypeIndex >= 0) {
        const newProductType = productTypes[newProductTypeIndex];
        productTypes[newProductTypeIndex] = {
          ...newProductType,
          products: newProductType.products.concat([
            {
              id: res.id,
              code: res.code,
              name: res.name,
            },
          ]),
        };
      }
    }

    product.id = res.id;
    product.program.id = res.programId;
    product.product_type_id = res.productTypeId;
    product.name = res.name;
    product.code = res.code;
    product.description = res.description;
    product.risk_type = res.riskType;
    return {
      ...state,
      product,
      productTypeList: productTypes,
      isSavingProductSettings: false,
      savedProductSettings: {
        name: res.name,
        description: res.description,
        code: res.code,
        riskType: res.riskType!,
      },
    };
  }),
  on(ProductActions.saveProductParameters, (state): State => {
    return { ...state, isSavingProductParameters: true };
  }),
  on(ProductActions.saveProductParametersFailure, (state): State => {
    return { ...state, isSavingProductParameters: false };
  }),
  on(ProductActions.saveProductParameterKeysSuccess, (state, action): State => {
    if (!state.product) {
      return state;
    }
    return {
      ...state,
      product: ProductReducersMapping.updateParameterKeyIds(
        state.product,
        action.upserts
      ),
      deletedNonCoverageParameterKeys: [],
    };
  }),
  on(ProductActions.saveProductParametersSuccess, (state, _action): State => {
    return {
      ...state,
      isSavingProductParameters: false,
    };
  }),
  on(ProductActions.saveProductCoverages, (state): State => {
    return { ...state, isSavingCoverages: true };
  }),
  on(ProductActions.saveProductCoveragesFailure, (state): State => {
    return { ...state, isSavingCoverages: false };
  }),
  on(ProductActions.saveProductCoverageKeysSuccess, (state, action): State => {
    if (!state.product) {
      return state;
    }
    return {
      ...state,
      product: ProductReducersMapping.updateParameterKeyIds(
        state.product,
        action.upserts
      ),
      deletedCoverageParameterKeys: [],
    };
  }),
  on(ProductActions.saveProductCoveragesSuccess, (state, _action): State => {
    return {
      ...state,
      isSavingCoverages: false,
    };
  }),
  on(ProductActions.addProductOption, (state, action): State => {
    // TODO: remove !
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const productCopy = DRYUtility.deepCopy(state.product!);
    const product = ProductReducersMapping.mapNewOptionToProductState(
      productCopy,
      action.option
    );
    return { ...state, product };
  }),
  on(ProductActions.updateProductOption, (state, action): State => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const productCopy = DRYUtility.deepCopy(state.product!);
    const product = ProductReducersMapping.mapUpdatedOptionToProductState(
      productCopy,
      action.updatedOption,
      action.originalOption
    );
    return { ...state, product };
  }),
  on(ProductActions.deleteProductOption, (state, action): State => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const productCopy = DRYUtility.deepCopy(state.product!);
    const product = ProductReducersMapping.mapDeletedOptionToProductState(
      productCopy,
      action.option
    );
    return {
      ...state,
      product,
      deletedNonCoverageParameterKeys:
        action.option.parameterKeyId && action.option.parameterKeyId > 0
          ? state.deletedNonCoverageParameterKeys.concat(action.option)
          : state.deletedNonCoverageParameterKeys,
    };
  }),
  on(ProductActions.addStandaloneCoverage, (state, action): State => {
    // TODO: remove !
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const productCopy = DRYUtility.deepCopy(state.product!);
    const product =
      ProductReducersMapping.mapNewStandaloneCoverageToProductState(
        productCopy,
        action.coverage,
        action.coverageParam
      );

    return { ...state, product };
  }),
  on(ProductActions.removeStandaloneCoverage, (state, action): State => {
    // TODO: remove !
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const productCopy = DRYUtility.deepCopy(state.product!);
    const product =
      ProductReducersMapping.mapRemoveStandaloneCoverageToProductState(
        productCopy,
        action.coverage,
        action.coverageParam
      );
    return {
      ...state,
      product,
      deletedCoverageParameterKeys:
        action.coverage.id && action.coverage.id > 0
          ? state.deletedCoverageParameterKeys.concat(action.coverage)
          : state.deletedCoverageParameterKeys,
    };
  }),
  on(ProductActions.updateStandaloneCoverage, (state, action): State => {
    // TODO: remove !
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const productCopy = DRYUtility.deepCopy(state.product!);
    const product =
      ProductReducersMapping.mapUpdateStandaloneCoverageToProductState(
        productCopy,
        action.updatedCoverage,
        action.coverageParam,
        action.originalCoverage
      );
    return { ...state, product };
  }),
  on(ProductActions.addBundledCoverage, (state, action): State => {
    // TODO: remove !
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const productCopy = DRYUtility.deepCopy(state.product!);
    const product = ProductReducersMapping.mapNewBundledCoverageToProductState(
      productCopy,
      action.coverage,
      action.coverageParam
    );
    return { ...state, product };
  }),
  on(ProductActions.removeBundledCoverage, (state, action): State => {
    // TODO: remove !
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const productCopy = DRYUtility.deepCopy(state.product!);
    const product =
      ProductReducersMapping.mapRemoveBundledCoverageToProductState(
        productCopy,
        action.coverage,
        action.coverageParam
      );
    return {
      ...state,
      product,
      deletedCoverageParameterKeys:
        action.coverage.id && action.coverage.id > 0
          ? state.deletedCoverageParameterKeys.concat(action.coverage)
          : state.deletedCoverageParameterKeys,
    };
  }),
  on(ProductActions.updateBundledCoverage, (state, action): State => {
    // TODO: remove !
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const productCopy = DRYUtility.deepCopy(state.product!);
    const product =
      ProductReducersMapping.mapUpdateBundledCoverageToProductState(
        productCopy,
        action.updatedCoverage,
        action.coverageParam,
        action.originalCoverage
      );
    return { ...state, product };
  }),
  on(ProductActions.addBucketClicked, (state): State => {
    return { ...state, showCreateBucketModal: true };
  }),
  on(ProductActions.addParameterSuccess, (state, action): State => {
    if (!state.product) {
      return state;
    }

    const parameterSubtypes: ParameterSubtypeFieldsFragment[] =
      action.parameterSubTypes.map((subtype) => {
        return {
          id: subtype.id,
          sort_order: subtype.sortOrder,
          parameter_type_id: subtype.parameterTypeId,
          subtype: subtype.subtype,
          control_type: subtype.controlType,
          visible: subtype.visible,
          is_identifier: subtype.isIdentifier,
          isUnique: subtype.isUnique,
          isGlobalUnique: subtype.isGlobalUnique,
          options: subtype.parameterSubtypeOptions,
        };
      });

    return {
      ...state,
      parameters: [
        ...state.parameters,
        {
          name: action.name,
          id: action.id,
          description: action.description,
          parameter_type: {
            id: action.parameterTypeId,
            description: action.parameterType.description,
            parameter_subtypes: parameterSubtypes,
            type: action.parameterType.type,
            bundleable: action.parameterType.bundleable,
          },
        },
      ],
    };
  }),
  on(ProductActions.addProductSuccess, (state, action) => {
    if (!state.productTypeList) {
      return state;
    }

    return {
      ...state,
      productTypeList: addProduct(
        state.productTypeList,
        action.id,
        action.code,
        action.name,
        action.productTypeId
      ),
    };
  }),
  on(ProductActions.copyProductSuccess, (state, action) => {
    if (!state.productTypeList || !state.product) {
      return state;
    }

    return {
      ...state,
      productTypeList: addProduct(
        state.productTypeList,
        action.id,
        action.code,
        action.name,
        state.product.product_type_id
      ),
    };
  })
);

function addProduct(
  productTypeList: IAProductType[],
  id: number,
  code: string,
  name: string,
  productTypeId: number
): IAProductType[] {
  const productTypeIndex = productTypeList.findIndex(
    (pt) => pt.id === productTypeId
  );

  if (productTypeIndex === -1) {
    return productTypeList;
  }

  const productType = productTypeList[productTypeIndex];

  productTypeList = productTypeList.slice();
  productTypeList[productTypeIndex] = {
    ...productType,
    products: productType.products.concat([
      {
        id,
        code,
        name,
      },
    ]),
  };
  return productTypeList;
}
