import { trpcClient } from '@roadrunner/shared/util-trpc';
import { Apollo } from 'apollo-angular';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IBundledCoverageParameterDiscountVM } from '../../../models/view-models/buckets/bucket.view-model';
import { SaveBucketBundleDiscountsResponse } from './save-bucket-bundle-discounts.interface';

export class SaveBucketBundleDiscountsData {
  static saveBucketBundleDiscounts(
    apollo: Apollo,
    bucketId: number,
    bundleDiscounts: IBundledCoverageParameterDiscountVM[]
  ): Observable<SaveBucketBundleDiscountsResponse> {
    const discounts = bundleDiscounts.map((d) => {
      return {
        bucket_id: bucketId,
        parameter_sub_key_id: d.parameterSubKeyId,
        discount: d.discount,
      };
    });

    return from(
      trpcClient.baseRates.saveBucketBundleDiscounts.mutate({
        discounts
      })
    ).pipe(map((res) => res));
  }
}
