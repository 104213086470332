import { Apollo } from 'apollo-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterOutput, trpcClient } from '@roadrunner/shared/util-trpc';
import { UpsertProductSettingsResponse } from './upsert-product-settings.interface';

export class UpsertProductSettingsData {
  static upsertProductSettings(
    apollo: Apollo,
    productId: number,
    productTypeId: number,
    programId: number,
    name: string,
    description: string,
    code: string,
    riskType: string
  ): Observable<UpsertProductSettingsResponse> {
   
    return from(trpcClient.productsettings.upsertProductSettings.mutate({
      programId:programId,
      code:code,
      id:productId,
      name:name,
      description:description,
      riskType:riskType,
      productTypeId:productTypeId}))
     
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((res) => res!))
    
  }
}
