import { ControlType } from '@roadrunner/rating-utility/data-access-parameter-type';
import {
  IParameterKeyValueVM,
  IParameterSubTypeVM,
  IProductParameterKeyVM,
  IProductParameterVM,
} from '../../models/view-models/products/product-options.view-model';
import { ParameterFieldsFragment, ParameterKeyFieldsFragment } from './product.actions';

export function toProductParameter(
  param: ParameterFieldsFragment,
  allProductParameterKeys: ParameterKeyFieldsFragment[]
): IProductParameterVM {
  // TODO: remove !
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const subTypes = param.parameter_type
    .parameter_subtypes!.slice()
    .sort((a, b) => {
      return a.sort_order - b.sort_order || a.id - b.id;
    });

  return {
    parameterId: param.id,
    parameterName: param.name,
    description: param.description,
    parameterType: {
      parameterTypeID: param.parameter_type.id,
      description: param.parameter_type.description,
      type: param.parameter_type.type,
      bundleable: param.parameter_type.bundleable,
      // TODO: remove !
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parameterSubTypes: param.parameter_type
        .parameter_subtypes!.map(
          (sub): IParameterSubTypeVM => ({
            parameterSubTypeID: sub.id,
            parameterTypeID: param.parameter_type.id,
            subType: sub.subtype,
            controlType: sub.control_type as ControlType,
            options: sub.options,
            isIdentifier: sub.is_identifier,
            isUnique: sub.isUnique,
            isGlobalUnique: sub.isGlobalUnique,
            visible: sub.visible,
            sortOrder: sub.sort_order,
          })
        )
        .sort((a, b) => a.parameterSubTypeID - b.parameterSubTypeID),
    },
    keys: allProductParameterKeys
      .filter((pk) => pk.parameter_id === param.id)
      // TODO: remove !s
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .sort((pk1, pk2) => pk1.sort_order! - pk2.sort_order!)
      .map(
        (pk): IProductParameterKeyVM => ({
          parameterKeyId: pk.id,
          parameterId: param.id,
          parameterKey: pk.key,
          productId: pk.product_id,
          sortOrder: pk.sort_order,
          parameterKeyValues: subTypes.map((pst): IParameterKeyValueVM => {
            const pkv = pk.parameter_key_values.find(
              (pkv) => pkv.parameter_subtype_id === pst.id
            );

            const parameterSubType: IParameterSubTypeVM = {
              parameterSubTypeID: pst.id,
              parameterTypeID: pst.parameter_type_id,
              subType: pst.subtype,
              controlType: pst.control_type as ControlType,
              options: pst.options,
              isIdentifier: pst.is_identifier,
              isUnique: pst.isUnique,
              isGlobalUnique: pst.isGlobalUnique,
              visible: pst.visible,
              sortOrder: pst.sort_order,
            };

            let value = pkv?.value;

            if (parameterSubType.controlType === ControlType.Checkbox) {
              value = booleanToString(
                value as unknown as string | boolean | undefined | null
              );
            }

            return {
              parameterKeyID: pk.id,
              // TODO: remove !s
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
              parameterKeyValueID: pkv?.id!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              value: value!,
              parameterSubType,
            };
          }),
          subKeys: pk?.parameter_sub_keys
            ? pk.parameter_sub_keys.map(
                ({ child_parameter_key }): IProductParameterKeyVM => ({
                  parameterKeyId: child_parameter_key.id,
                  parameterId: param.id,
                  parameterKey: child_parameter_key.key,
                  productId: child_parameter_key.product_id,
                  sortOrder: child_parameter_key.sort_order,
                  parameterKeyValues: [],
                  subKeys: [],
                })
              )
            : null,
        })
      ),
  };
}

export function booleanToString(
  value: string | boolean | undefined | null
): string | undefined {
  return (value || false).toString().toUpperCase();
}
