import { createAction, props } from '@ngrx/store';
import { IUpdateProgram } from '../program.service';
import {
  AddProgramVariables,
  InsertOneProgramResponse,
  Program
} from './program.models';

export const init = createAction('[Program Page] Init');

export const loadProgramSuccess = createAction(
  '[Program/API] Load Program Success',
  props<{ program: Program[] }>()
);

export const loadProgramFailure = createAction(
  '[Program/API] Load Program Failure'
);

export const addProgramClicked = createAction(
  '[Choose Program Component] Add Program Clicked'
);

export const addProgram = createAction(
  '[Add Program Dialog] Add Program Submit',
  props<{ program: AddProgramVariables }>()
);

export const addProgramCancelled = createAction(
  '[Add Program Dialog] Add Program Cancelled'
);

export const addProgramSuccess = createAction(
  '[Program/API] Add Program Success',
  props<{ program: InsertOneProgramResponse }>()
);

export const addProgramFailure = createAction(
  '[Program/API] Add Program Failure',
  props<{ error: unknown }>()
);

export const editProgramClicked = createAction(
  '[Page Layout Component] Edit Program Clicked'
);

export const editProgram = createAction(
  '[Add Program Dialog] Edit Program Submit',
  props<{ program: IUpdateProgram }>()
);

export const editProgramCancelled = createAction(
  '[Edit Program Dialog] Edit Program Cancelled'
);

export const editProgramSuccess = createAction(
  '[Program/API] Edit Program Success',
  props<{ program: IUpdateProgram }>()
);

export const editProgramFailure = createAction(
  '[Program/API] Edit Program Failure',
  props<{ error: unknown }>()
);

export const productLoaded = createAction(
  '[Products] Product Loaded',
  props<{ programId: number }>()
);

export const productRatesLoaded = createAction(
  '[Rates] Product Rates Loaded',
  props<{ programId: number }>()
);
