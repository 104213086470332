import { Injectable } from '@angular/core';
import {
  trpcClient
} from '@roadrunner/shared/util-trpc';
import { Apollo } from 'apollo-angular';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AddParameterVariables, InsertOneParameterResponse,
  InsertOneProductResponse,
  ProductInput
} from './add-product.mutation';


@Injectable({ providedIn: 'root' })
export class ProductsService {
  constructor(private apollo: Apollo) {}

  add(product: ProductInput): Observable<InsertOneProductResponse> {
    return from(
      trpcClient.product.addProduct.mutate({
        code: product.code,
        description: product.description,
        name: product.name,
        product_type_id: product.product_type_id,
        program_id: product.program_id,
        risk_type: product.risk_type,
      })
    ).pipe(map((response) => response.insert_product_one));
  }

  addParameter(
    parameter: AddParameterVariables
  ): Observable<InsertOneParameterResponse> {
    return from(
      trpcClient.productParameter.addParameter.mutate({
        name: parameter.name,
        description: parameter.description,
        parameterTypeId: parameter.parameterTypeId,
      })
    ).pipe(
      map(
        (response) => 
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        response.insert_parameter_one
      )
    );
  }
}
